import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getStoryLandBookInfo } from '../api/LibraryAPI';

const FromMagickid = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAndNavigate = async () => {
            const queryParams = new URLSearchParams(location.search);
            const USER_ID = queryParams.get('userID');
            const USER_NAME = queryParams.get('userName');
            const LOGIN_TIME = queryParams.get('loginTime');
            const PAGE = queryParams.get('page');

            localStorage.setItem('userID', USER_ID);
            localStorage.setItem('userName', USER_NAME);
            localStorage.setItem('loginTime', LOGIN_TIME);

            console.log('userID: ', USER_ID);
            console.log('PAGE: ', PAGE);

            if (USER_ID && USER_NAME && LOGIN_TIME && PAGE === 'selecting') {
                navigate('/selecting');
            } else if (USER_ID && USER_NAME && LOGIN_TIME && PAGE === 'storyland') {
                navigate('/storyland');
            } else if (USER_ID && USER_NAME && LOGIN_TIME && PAGE === 'storyland-reading') {
                const BOOK_ID = queryParams.get('bookId');
                try {
                    const result = await getStoryLandBookInfo(BOOK_ID); // 비동기 함수 호출
                    console.log('Story Info: ', result); // 필요 시 처리
                    if (result.success) {
                        const book_info = result.data[0]
                        navigate('/reading', {
                            state: {
                                bookList: book_info,
                                pageName: 'storyland',
                                bookID: BOOK_ID,
                            },
                        });
                    }
                    else {
                        navigate('/storyland');
                    }

                } catch (error) {
                    console.error('Error fetching story info:', error);
                    navigate('/logout'); // 오류 발생 시 로그아웃으로 이동
                }

            } else {
                navigate('/logout');
            }
        };

        fetchAndNavigate(); // 비동기 함수 호출
    }, [navigate, location.search]);

    return null;
};

export default FromMagickid;
