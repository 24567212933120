import { performRequest } from './utils/RequestUtils';
import { LIBRARY_ENDPOINT } from './utils/EndPoint';

export const getMybookList = async (user_id) => {
    return performRequest('get', `${LIBRARY_ENDPOINT}/${user_id}`);
};

export const getStoryLandList = async () => {
    return performRequest('get', `${LIBRARY_ENDPOINT}/storyland`);
};

export const getStoryLandBookInfo = async (book_id) => {
    return performRequest('get', `${LIBRARY_ENDPOINT}/storyland/${book_id}`);
};


export const logStorylandReading = async (user_id, book_id) => {
    return performRequest('get', `${LIBRARY_ENDPOINT}/storyland/reading/${user_id}/${book_id}`);
};

export const getStoryLandforLoading = async () => {
    return performRequest('get', `${LIBRARY_ENDPOINT}/storyland/loading`);
};

export const getBookContents = async (book_id) => {
    return performRequest('get', `${LIBRARY_ENDPOINT}/contents/${book_id}`);
};