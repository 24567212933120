import React, { useState } from 'react';
import '../styles/BookItem.scss';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import TextToSpeech from './TextToSpeech';

const BookItem = ({ bookList, pageName }) => {
    console.log(`bookList : ${bookList}`);
    console.log(`pageName : ${pageName}`);
    console.log(bookList.series_name);

    const navigate = useNavigate();
    // 2024-02-14 | 각 책 아이템마다 음성 재생 상태를 관리하기위한 state
    const [speechState, setSpeechState] = useState(bookList.map(() => false));

    const goReadingPage = (book_id, i) => {
        console.log('book+_id : ', book_id);
        localStorage.setItem('book_id', book_id);
        // navigate('/reading', {state: { contents_json: bookList[0].contents_json, pageName: pageName}});
        navigate('/reading', {
            state: {
                bookList: bookList[i],
                pageName: pageName,
                bookID: book_id,
            },
        });
    };

    const printDate = (date) => {
        console.log(date);
        const result_date = date.split('T');
        return result_date[0];
    };

    // 음성 재생 상태를 토글
    const toggleSpeechState = (index) => {
        const update_speech_states = [...speechState];
        update_speech_states[index] = !update_speech_states[index];
        setSpeechState(update_speech_states);
    };

    return (
        <>
            {bookList.map((item, i) => (
                <div
                    className="book-item-test"
                    key={item.id}
                    onClick={() => goReadingPage(item.id, i)}
                >
                    <Link>
                        <figure className="book-cover">
                            <img
                                src={item.bookcover}
                                alt=""
                            />
                        </figure>
                        <figure className="book-bottom">
                            <img
                                src="/images/book-cover-bottom.svg"
                                alt=""
                            />
                        </figure>
                        <div className="book-name-container">
                            <h3 className="book-title">
                                {item.title}
                                <div className="book-title-stroke">
                                    {item.title}
                                </div>
                            </h3>
                            {pageName == 'myStorybook' && (
                                <p className="book-date">
                                    {printDate(item.created_date)}
                                </p>
                            )}
                        </div>
                    </Link>
                    <TextToSpeech
                        text={item.title}
                        // props 추가
                        speechState={speechState[i]}
                        onChange={() => toggleSpeechState(i)}
                    />
                    {pageName !== 'myStorybook' && (
                        <figure className="bean-circle">
                            <img
                                src={'/images/bean-circle.svg'}
                                alt=""
                            />
                        </figure>
                    )}
                </div>
            ))}
        </>
    );
};

export default BookItem;
